import styled from 'styled-components';
import * as Style from "./StyledComponents";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";

function About() {

  return (
    <Style.Window>
        <Style.WindowTitleBar>
          <Style.WindowTitle>Not Found</Style.WindowTitle>
        </Style.WindowTitleBar>

        <Style.WindowContent>
            <p><strong>Oops, site not found!</strong></p>
             <p>[ <Link to={`/`}>Return Home</Link> ]</p>
        </Style.WindowContent>
    </Style.Window>
  )
}


function ErrorPage() {
  return (
    <Style.Background color={"#ad4141"}>
      <Navbar/>
      <Style.Container>

        <Style.ColumnLeft>
          <About/>
        </Style.ColumnLeft>
      </Style.Container>
    </Style.Background>
  );
}

export default ErrorPage;
