// src/StyledComponents.js
import styled, { keyframes }  from 'styled-components';

import backgroundImage from '../images/bg.png'; // Replace with the path to your image


const mobileBreakpoint = '768px';

export const UnicodeIcon = styled.span`
  font-family:inherit;
`;

export const IconLogo = ()=>{
  return (
      <UnicodeIcon>🌼</UnicodeIcon>
  )
};

export const IconSquare = ()=>{
  return (
      <UnicodeIcon>▪</UnicodeIcon>
  )
};


export const Background = styled.div`
  //background-image: url(${backgroundImage});
  //background-repeat: repeat;
  //background-attachment: fixed; // This will fix the background position while scrolling
  width: 100%;
  min-height: 100vh;
  height: auto;
  
  background-color: ${props => props.color || '#ccc'};
  transition: background-color 0.5s ease; // This line adds the transition effect
  
  color: ${props => props.textcolor || 'black'};
  //padding-bottom:40px;

  a {
    color: ${props => props.linkcolor || '#232eed'};
  }
`;

export const Divider = styled.hr`
  border-top: 1px dotted #000;
  height: 0;
  border-bottom: none;
`;

export const Navbar = styled.nav`
  background-color: #111111;
  z-index:999;
  position: relative;
  color: white;
  padding: 10px 10px 10px 10px;
  margin-bottom: 60px;
  display: flex;
  align-items: center;

  a {
    font-weight: bold;
    color: white;
    text-decoration: none;
    margin-left: 0.75em;
    margin-right: 0.75em;
  }

  a:hover {
    text-decoration: underline;
  }

`;

export const HiddenMobile = styled.span`

 @media (max-width: ${mobileBreakpoint}) {
    display: none; // hide the component on mobile screens
  }
`;

export const ShowMobile = styled.span`

 @media (min-width: ${mobileBreakpoint}) {
    display: none; // hide the component on mobile screens
  }
`;

export const NavbarLinks = styled.span`
  display: flex; // To layout the links horizontally
  margin-left: auto; // Push the span to the far right
  color: #777;
`;


export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center; // Center the columns if they are less than the container's width
`;

export const Break = styled.div`
  height: 1em;
`;


export const WindowImage = styled.img`
  width: 100%;
  margin: 0 0 8px 0;
  padding: 0;
`;

export const Window = styled.div`
  background-color: black;
  border: 1px solid white;
  position: relative;
  margin-bottom: 20px;

  z-index: 0;

  p {
    margin: 0;
    padding: 0;
  }
`;

export const Rectangle = styled.div`
  width: 13px;
  height: 13px;
  border: 1px solid white;
  position: absolute;
  right: 2px; // Right aligned
`;

export const WindowContent = styled.div`
  background-color: ${props => props.bgColor || 'white'};
  align-items: center;
  justify-content: center;

  border: 1px solid black;
  margin: 0 2px 2px 2px;
  padding: 10px;
`;

export const ShowJson = styled.pre `
  word-wrap: break-word;
  white-space: pre-wrap;
`;

export const WindowTitleBar = styled.div`
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 0 2px 0;
`;

export const WindowTitle = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: white;
`;

export const ColumnLeft = styled.div`
  width: 300px;
  padding: 0px;
  margin: 0 7px;

   @media (max-width: ${mobileBreakpoint}) {
    width:450px;
  }
`;

export const ColumnWide = styled.div`
  width: 500px;
  padding: 0px;
  margin: 0 7px;
`;


export const ColumnRight = styled.div`
  width: 450px;
  padding: 0px;
  margin: 0 7px;
`;

export const FaqItem = styled.div`
  border-bottom: 1px dotted black;
  padding-top:5px;
  padding-bottom:5px;
  margin-bottom:10px;
`;

export const FaqHeader = styled.h1`
  margin-bottom: 5px;
`;

export const FaqContent = styled.div`
  p {
    margin-bottom:1em;
  }
`;

export const TextInput = styled.textarea`
  padding: 3px 10px;
  border: 1px solid #333;
  transition: border-color 0.2s ease;
  width: 100%;
  box-sizing: border-box;
  height: 5em;
  resize: vertical;

  background-color: ${({ disabled }) => (disabled ? '#ccc' : 'white')};

  &:focus {
    border-color: #0077b6;
    outline: none;
    background-color:#ffffcc;
  }
`;

export const TextInputSingleLine = styled.textarea`
  padding: 3px 10px;
  border: 1px solid #333;
  transition: border-color 0.2s ease;
  width: 100%;
  box-sizing: border-box;
  height: 2em;
  resize: none;

  background-color: ${({ disabled }) => (disabled ? '#ccc' : 'white')};

  &:focus {
    border-color: #0077b6;
    outline: none;
    background-color:#ffffcc;
  }
`;


export const Button = styled.button`
  padding: 3px 10px;
  margin-right: 2px;
  font-weight: bold;

  background-color: ${({ disabled }) => (disabled ? '#ccc' : 'yellow')};
  color:${({ disabled }) => (disabled ? 'grey' : 'black')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  // To remove any rounding, set the border-radius to 0
  //border-radius: 0;
  border: ${({ disabled }) => (disabled ? '1px solid grey' : '1px solid black')};

  &:active {
    background-color: ${({ disabled }) => (disabled ? '#ccc' : 'blue')};
    color: ${({ disabled }) => (disabled ? 'grey' : 'white')};
  }
`;

export const UserInputError = styled.div`
  color: red;
  word-wrap: break-word;
  white-space: normal;
`;

export const ConnectWalletArea = styled.div`
  background-color: ${props => props.bgcolor || '#2eef72'};
  color: black;
  padding: 10px;
  margin: 5px 0;
  border-radius:3px;
  word-wrap: break-word;
  white-space: normal;
  border: 1px solid black;
  text-align:center;

  a {
    color: blue;
  }
`;

export const MintTokenArea = styled.div`
  background-color: ${props => props.bgcolor || '#2eef72'};
  color: black;
  padding: 15px;
  margin: 5px 0;
  border-radius:3px;
  word-wrap: break-word;
  white-space: normal;
  border: 1px solid black;

  a {
    color: blue;
  }
`;
export const TransactionErrorMsg = styled.div`
  background-color:red;
  color: yellow;
  padding: 10px;
  margin: 5px 0;
  word-wrap: break-word;
  border-radius:3px;
  white-space: normal;
  border: 1px solid black;
`;

export const TransactionSuccessMsg = styled.div`
  background-color:#00ff5a;
  color: black;
  padding: 10px;
  margin: 5px 0;
  word-wrap: break-word;
  border-radius:3px;
  white-space: normal;
  border: 1px solid black;

  a {
    color: blue;
  }
`;

export const InfoMsg = styled.div`
  background-color:#f7ff7a;
  color: black;
  padding: 10px;
  margin: 5px 0;
  word-wrap: break-word;
  border-radius:3px;
  white-space: normal;
  border: 1px solid black;
  text-align:center;

  a {
    color: blue;
  }
`;

export const CenterColumn = styled.div`
  width: 400px;
  padding: 0px 20px;
  //background-color: red;
`;

const rainbow = keyframes`
  0% { color: red; }
  14% { color: orange; }
  28% { color: yellow; }
  42% { color: green; }
  57% { color: blue; }
  71% { color: indigo; }
  85% { color: violet; }
  100% { color: red; }
`;

export const RainbowSquareStyled = styled.span`
  display: inline-block;
  font-size: 1.5em;
  animation: ${rainbow} 0.1s infinite;
`;