import styled from 'styled-components';
import { ethers } from 'ethers';

import * as Style from "./StyledComponents";
import Navbar from "./Navbar";

import React, {useState, useEffect} from 'react'
import {merkleRoot, merkleLeafHex, merkleCheckAddress, merkleProof, containsAddressInList, getAddressList} from '../merkle/use-merkle'


function isValidEthereumAddress(address) {
    try {
        return ethers.isAddress(address);
    } catch (error) {
        return false;
    }
}

function checkWhitelisted(address) {
    try {
        return containsAddressInList(address);
    } catch (error) {
        return false;
    }
}

function MerkleTest() {

  const [walletAddress, setWalletAddress] = useState("");
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [isValidEthWallet, setIsValidEthWallet] = useState(false);

  function handleTextInput(event) {
    const newWalletAddress = event.target.value.toLowerCase();
    setWalletAddress(newWalletAddress);
  }

  useEffect(()=>{
    if (walletAddress !== ""){
      setIsValidEthWallet(isValidEthereumAddress(walletAddress));
      setIsWhitelisted(checkWhitelisted(walletAddress));
    } else {
      setIsValidEthWallet(false);
      setIsWhitelisted(false);
    }

  }, [walletAddress]);

  return (
    <Style.Window>
        <Style.WindowTitleBar>
          <Style.WindowTitle>✔️ Whitelist Checker Tool</Style.WindowTitle>
        </Style.WindowTitleBar>

        <Style.WindowContent>
            <p>Check whitelist elligibiliy by typing in your full ETH wallet address below.</p>

            <Style.Break/>
                <p><strong>ETH Wallet Address:</strong></p>
               <Style.TextInputSingleLine type="text" value={walletAddress} onChange={handleTextInput} placeholder="(example: 0xfffff...)" />
            <Style.Break/>

             {isWhitelisted && <Style.TransactionSuccessMsg><strong>{walletAddress}</strong> is whitelisted! ✔️</Style.TransactionSuccessMsg>}
            {isValidEthWallet  && !isWhitelisted && walletAddress !== "" &&  <Style.TransactionErrorMsg><strong>{walletAddress}</strong> is not whitelisted! ❌ </Style.TransactionErrorMsg>}
            {!isValidEthWallet && walletAddress !== "" &&  <Style.TransactionErrorMsg><strong>{walletAddress}</strong> is not a valid wallet address! ❌ </Style.TransactionErrorMsg>}
        </Style.WindowContent>
    </Style.Window>
  )
}

function Merkle() {
    return (
      <>
       <Style.ColumnWide>
          <MerkleTest/>
        </Style.ColumnWide>
        </>
    )
}

function WhitelistCheckerPage() {
  return (
    <Style.Background color={"#77c8cb"}>
      <Navbar/>
      <Style.Container>
       
      <Merkle/>
      </Style.Container>
    </Style.Background>
  );
}

export default WhitelistCheckerPage;
