import { base, baseGoerli } from 'wagmi/chains'

const hidden = false;

const NETWORK = "base";
//const NETWORK = "baseGoerli";

const contractABI = require("./abi/CommunityGarden.json");

const CONTRACT_ADDRESS_BASE = `0xe7f0f2f56640b494238761cc961b5bf248979ea4`;
const CONTRACT_ADDRESS_BASE_GOERLI = `0xa2Cb3f90C274F136467374a1638ac2889a0b885c`;

//const serverUrl = `https://community-garden-api.up.railway.app`;
const serverUrl = `https://api.communitygardens.art`;
//const serverUrl = `http://localhost:3005`;

const currNetwork = NETWORK==="base" ? base: baseGoerli;
const contractAddress = NETWORK==="base" ? CONTRACT_ADDRESS_BASE : CONTRACT_ADDRESS_BASE_GOERLI;

const getTxnUrl = (hash, chainId)=> {
  switch(chainId) {
    case 8453:
      return `https://basescan.org/tx/${hash}`;
    case 84531:
      return `https://goerli.basescan.org/tx/${hash}`;
    default:
      return `https://etherscan.io/tx/${hash}`;
  }
}

const getTokenViewOpenSeaUrl = (tokenId)=> {
  switch(NETWORK) {
    case 'baseGoerli':
      return `https://testnets.opensea.io/assets/base-goerli/${contractAddress}/${tokenId}`;
    default:
      return `https://opensea.io/assets/base/${contractAddress}/${tokenId}`;
  }
}


const getAccountUrl = (hash, chainId)=> {
  switch(chainId) {
    case 8453:
      return `https://basescan.org/address/${hash}`;
    case 84531:
      return `https://goerli.basescan.org/address/${hash}`;
    default:
      return `https://etherscan.io/address/${hash}`;
  }
}

const network = {
  chain: currNetwork,
  getTxnUrl: (hash)=>{return getTxnUrl(hash, currNetwork.id);},
  getAccountUrl: (hash)=>{return getAccountUrl(hash, currNetwork.id);},
};

const contract = {
  abi: contractABI.abi,
  address : contractAddress,
  etherscan  : `${network.getAccountUrl(contractAddress)}#code`,
}


console.log(`Contract Etherscan (${currNetwork.name}) ${contract.etherscan}`);

const links = {
  twitter: "https://twitter.com/dailofrog",
  view: "https://twitter.com/dailofrog",
  linktree: "https://linktr.ee/dailofrog",
  opensea: "https://opensea.io/collection/communitygardens",
};

export {
  links, 
  contract, 
  network,
  hidden,
  getTokenViewOpenSeaUrl,
  serverUrl
};
