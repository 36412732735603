import styled from 'styled-components';

import * as Style from "./StyledComponents";
import {links, contract, network, hidden, serverUrl, getTokenViewOpenSeaUrl} from "../Config";
import React, {useState, useEffect} from 'react'
import { Link } from "react-router-dom";

function FooterBar({tokenId}) {

  const [hidden, setHidden] = useState(false);

  const Footer = styled.nav`
    background-color: black;
    color: white;
    padding: 10px 10px 10px 10px;
    position: absolute;
    bottom: 0;
    right: 0;

    a {
      font-weight: bold;
      color: white;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }

  `;

  return (
    <>
      { !hidden && 
      <Footer>
        <div>
          <Link to={`/token/${tokenId}`}>{'←'} Community Garden #{tokenId}</Link> | <a target="_blank" href={getTokenViewOpenSeaUrl(tokenId)}>OpenSea</a> | <a href="#" onClick={(event)=>{event.preventDefault(); console.log("HIDE");setHidden(true)}}>Hide</a>
        </div>
      </Footer>
      }
    </>
  )
}

function SingleGardenView({tokenId}) {

  const [error, setError] = useState(null);

  const [backgroundColor, setBackgroundColor] = useState("#ccc");
  const [selectedTokenId, setSelectedTokenId] = useState(tokenId);
  const [selectedTokenData, setSelectedTokenData] = useState(null);


  // fetch token data
  useEffect(()=>{
    if (selectedTokenId == null)
      return;

    fetch(`${serverUrl}/garden/${selectedTokenId}`)
      .then(response => response.json())
      .then(data => {
        setSelectedTokenData(data);
        //setBackgroundColor(data.bgColor);
      })
      .catch(error => {
        console.error(`Error fetching token #${selectedTokenId} data:`, error);
        setError(error.toString());
      });

  },[selectedTokenId]);

const CenteredContainer = styled.div`
  display: flex;
  align-items: center; // This centers vertically
  justify-content: center; // This centers horizontally
  height: 100vh; // Full height of the viewport
`;

const GardenIFrame = styled.iframe`
  width: 100%;
  border: 0px;
  height: 100vh;
`;

  return (
        <>

          {selectedTokenId && <>

            {selectedTokenData && <>
              
                <CenteredContainer>
                  <GardenIFrame srcDoc={(selectedTokenData.html)} title="Embedded HTML" width="100%" height="300"/>
                  <FooterBar tokenId={tokenId}/>
                </CenteredContainer>
            </>}
          </>}

        </>
  )
}

function ViewSinglePage({tokenId}) {
  return (
      <SingleGardenView tokenId={tokenId}/>
    
  );
}

export default ViewSinglePage;
