import * as Style from "./StyledComponents";
import React, { useState, useEffect } from 'react';
import {serverUrl, links, contract, network, getTokenViewOpenSeaUrl} from "../Config";
import styled from 'styled-components';
import Navbar from "./Navbar";

import { useAccount, useConnect, useDisconnect,  useNetwork, useBalance  } from 'wagmi'
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction, useContractRead, useEnsName } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { ethers } from 'ethers';
import {merkleRoot, merkleLeafHex, merkleCheckAddress, merkleProof, containsAddressInList, getAddressList} from '../merkle/use-merkle'

import { Link } from "react-router-dom";

const MERKLE_ROOT_NULL = "0x0000000000000000000000000000000000000000000000000000000000000000";

export const CommunityGardenHeader = styled.div`
  z-index:999;
  position: relative;
`;

export const GardenIFrame = styled.iframe`
  height: 600px;
  width: 500px;
  border: 0px;
  overflow:visible;
  //background-color: red;
  margin-bottom: -145px;
  margin-top: -150px;
`;

export const GardenVisualArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
`;

export const GardenDescription = styled.div`
  padding-bottom:20px;

  margin-top: 15px;
  //text-align: center;

  //display: flex;
  //align-items: center;
  //justify-content: center;
  //background-color:red;

  p {
    width: 350px;
    text-align:center;
    margin-left: auto;
    margin-right: auto;
    //background-color:orange;
  }
`;

export const GardenNavIcon = styled.span`
  //margin: 0px 25px;
  //background-color:red;
  width: 90px;
  display: block;
  text-align: center;
  
  a{
    text-decoration: none;
  }
`;

export const GardenNavMobile = styled.span`
  a{
    text-decoration: none;
  }
`;

export const GardenTokenIconsRow = styled.p`
  word-wrap: break-word;
`;

export const MintStausClosed = styled.span`
  color:red;
`;

export const MintStausOpen = styled.span`
  font-weight:bold;
  color:green;
`;

export const MintStausMintedOut = styled.span`
  color:black;
`;


const Nbsp  = () => '\u00A0';

function abbreviateAddress(address, charsLength = 4) {
  if (typeof address !== 'string') return ''
  if (address.length < charsLength * 2 + 2) return address

  const prefix = address.substr(0, charsLength + 2); // '0x' + charsLength characters
  const suffix = address.substr(-charsLength);

  return `${prefix}...${suffix}`;
}

function GardenView({tokenId}) {

  const [tokenData, setTokenData] = useState(null);

  const [backgroundColor, setBackgroundColor] = useState("#ccc");
  const [textColor, setTextColor] = useState("black");
  const [linkColor, setLinkColor] = useState("#232eed");

  function refreshTokenData() {
    console.log(`Fetching data for token ${tokenId}:`);

    // Fetch the data from the API
    fetch(`${serverUrl}/garden/${tokenId}`)
      .then(response => response.json())
      .then(data => {
        console.log(`Received data for token ${tokenId}:`, data);

        setTokenData(data);

        if ( data.error == null) {
          setBackgroundColor(data.bgColor);
          setTextColor(data.textColor);
          setLinkColor(data.linkColor);
        } else {
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  // get token data
  useEffect(() => {
    console.log("GardenView get tokenData for TokenID:", tokenId);
    refreshTokenData();
  }, [tokenId]);

  return (
      <>
        <Style.Background color={backgroundColor} textcolor={textColor} linkcolor={linkColor}>
        <Navbar/>
      
          <Style.Container>
            <Style.CenterColumn>
              <CommunityGardenHeader>
                <p>🌼 <strong>Community Gardens</strong> // by <a href={links.twitter} target="_blank">@dailofrog</a></p>
                <Style.Break/>
                {/*<p>Fully onchain community gardens + social art experiment. Mint a token inscribed with a personal message to embed into the shared ERC-1155 garden NFT.</p>*/}
                <p>Fully onchain interactive community gardens + ERC-1155 tokenized social art experiment on <a href="https://base.org/" target="_blank">Base</a>. Each garden visually represents its members by planting their personal messages onchain, creating a collective expression of community.</p>
                <Style.Break/>
                <p><em>This project is supported by the <a href={'https://paragraph.xyz/@grants.base.eth/calling-based-builders'} target="_blank">Base Builder Grant</a>.</em></p>
                <Style.Break/>
                <p>[ <Link to={`/about/`}>Learn More →</Link> ]</p>
              </CommunityGardenHeader>
            </Style.CenterColumn>
          </Style.Container>

          <Garden tokenId={tokenId} tokenData={tokenData} refreshTokenData={refreshTokenData}/>
       </Style.Background>
      </>
  )
}

function Balance() {

  const { address, isConnected } = useAccount()

  const { data:ethBalance } = useBalance({
    address: address,
  })

  return (
    <>
      {isConnected && <p><strong>Your Balance</strong>: Ξ {parseFloat(ethBalance?.formatted).toFixed(4)}</p>}
    </>
  )
}

function WalletInterface({tokenId, tokenData, refreshTokenData}) {

  const CHARACTER_LIMIT = 100;

  const { address, isConnected } = useAccount()
  const { chain} = useNetwork()
  const { connect } = useConnect({
    connector: new InjectedConnector(),
    chainId: network.chain.id,
  })
  const { disconnect } = useDisconnect();

  const [userMessage, setUserMessage] = useState("");

  const [userOwnedMints, setUserOwnedMints] = useState([]);

  function handleTextInput(event) {
    const message = event.target.value;
    setUserMessage(message);
  }

  const { data, isLoading, isSuccess, error, isError, write, reset } = useContractWrite({
    address: contract.address,
    abi: contract.abi,
    functionName: 'mintPublicSigned',
    chainId: network.chain.id,
    account: address,

    onSettled(data, error) {
      console.log('Transaction Settled', { data, error })
    },
    //functionName: 'mintAdmin',
  });

  async function fetchSignedMessage() {
    try {
      let response = await fetch(`${serverUrl}/message/sign`, {
        method: 'POST',
        mode: 'cors', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: userMessage,
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      let data = await response.json();
      return data;
    } catch (error) {
      console.error('Fetch error: ', error.message);
      throw error; // Re-throw to allow caller to handle the error as well
    }
  }

  async function handleMint() {

    const signedMessageData = await fetchSignedMessage();
    console.log("SIGNED", signedMessageData);

    // for validating message
    const sanitizedMessage = signedMessageData.sanitizedMessage; // remove html entities etc
    const signature = signedMessageData.signature;

    // for merkle
    let proof = [];

    // calc merkle
    if (tokenData.merkleRoot != MERKLE_ROOT_NULL && containsAddressInList(address)) {
      proof = merkleProof(address);
      console.log(`Fetching merkle proof for ${address}:`, proof);
    }

    const mintPrice = tokenData.price;

    // uint64 padded for extra bytes
    const extraData = 0; 

     write({
        args: [signature, proof, tokenId, sanitizedMessage, extraData ],
        from: address,
        value: ethers.parseEther(mintPrice),
      });
  }

  useEffect(()=>{
    reset();
  },[tokenId]);

  useEffect(()=>{
    if (address != null) {
      console.log("LOGGED ADDRESS:", address);
      const userMints = tokenData.mints.filter(item => item[0].toLowerCase() === address.toLowerCase());
      console.log(`Wallet ${address} owns mints of token ${tokenId}`, userMints);
      setUserOwnedMints(userMints);
    }
  },[address]);

  useEffect(()=>{
    if (isSuccess) {
      console.log("Successful mint!");

      // wait for some time to reload
      setTimeout(() => {
        refreshTokenData();
      }, 10000); // 10 seconds
    }
  },[isSuccess]);

  const userHasMaxMints = userOwnedMints.length >= tokenData.countPerWallet;
  const mintedOut = tokenData.numMinted >= tokenData.totalSupply;
  const isPassedMerkleTest = tokenData.merkleRoot === MERKLE_ROOT_NULL || containsAddressInList(address);

  if (isConnected) 

    return (
      <>
      <Style.MintTokenArea bgcolor={tokenData.mintBgColor}>
        <p><strong>Connected</strong>: ✔️<a target="_blank" rel="noreferrer" href={network.getAccountUrl(address)}>{abbreviateAddress(address)}</a> (<a href="#" onClick={() => disconnect()}>Disconnect</a>)</p>
        {chain && <p><strong>Network</strong>: {chain.name} ({chain.id})</p>}
        <Balance/>
        <p><strong>Community Garden #{tokenId} Supply</strong>: {tokenData.icon} {tokenData.numMinted}/{tokenData.totalSupply}</p>
        <p><strong>You Own</strong>: {tokenData.icon} {userOwnedMints.length}</p>
        {tokenData.merkleRoot !== MERKLE_ROOT_NULL && <p><strong>Whitelist Status</strong>: {isPassedMerkleTest ? "✔️ Whitelisted": "❌ Not Whitelisted"}</p>}

        <Style.Break/>
        <Style.Divider/>
        <Style.Break/>


        { isPassedMerkleTest && !userHasMaxMints && tokenData.mintable && tokenData.numMinted < tokenData.totalSupply &&
        <>
          <p><strong>Plant your {tokenData.icon} message into Community Garden #{tokenId}</strong>:</p>
          <Style.Break/>
          <Style.TextInput disabled={isLoading} value={userMessage} onChange={handleTextInput}  placeholder={`Your message (will be stored onchain and embedded into Community Garden #${tokenId})...`}></Style.TextInput>
          <p>(Character Limit: {userMessage.length}/{CHARACTER_LIMIT})</p>
          { userMessage.length == 0 && <Style.UserInputError>* Message cannot be empty</Style.UserInputError>}
          { userMessage.length > CHARACTER_LIMIT && <Style.UserInputError>* Message exceeded character limit ({CHARACTER_LIMIT})</Style.UserInputError>}

          <Style.Button  onClick={() => handleMint()} disabled={isLoading || userMessage.length == 0 || userMessage.length > CHARACTER_LIMIT}>Mint {tokenData.icon} ({tokenData.price} ETH + gas)</Style.Button>
          <Style.Break/>

          <p><em><strong>NOTE</strong>: Due to the gas-costs for storing text onchain, longer messages will require more gas (ETH) to mint.</em></p>
         
          {isSuccess && <Style.TransactionSuccessMsg>
              <p>🎉 <strong>Successfully minted!</strong></p>
              <Style.Break/>
              <p>👉 <a target="_blank" href={getTokenViewOpenSeaUrl(tokenId)}>View 'Community Garden #{tokenId}' on OpenSea</a></p>
              <Style.Break/>
              <p>Transaction Hash: <a target="_blank" href={network.getTxnUrl(data.hash)}>{abbreviateAddress(data.hash, 7)}</a></p>
            </Style.TransactionSuccessMsg>}

          {isError && <Style.TransactionErrorMsg>
              <p>⚠️ <strong>ERROR</strong>: {error.message}</p>
            </Style.TransactionErrorMsg>}
        </>
        }

        { mintedOut &&
          <>
          <p><strong>✔️ Community Garden #{tokenId} has been minted out!</strong></p>
          <p>(<a href={getTokenViewOpenSeaUrl(tokenId)} target="_blank">Check OpenSea</a> for secondary listings)</p>
          </>
        }

        { !tokenData.mintable && !mintedOut &&
          <>
          <p><strong>Minting of Community Garden #{tokenId} currently closed.</strong></p>
          </>
        }

        { userHasMaxMints && !mintedOut &&
          <p><strong>{tokenData.icon} You've surpassed the max number of mints ({tokenData.countPerWallet} max) for Community Garden #{tokenId}</strong>!</p>
        }

        { !isPassedMerkleTest && !mintedOut &&
          <p><strong>Sorry, you are not whitelisted to mint Community Garden #{tokenId}.</strong></p>
        }

        </Style.MintTokenArea>
      </>
    )

  return (
    <Style.ConnectWalletArea bgcolor={tokenData.mintBgColor}><a href="#" onClick={(event) => {event.preventDefault(); connect()}}><strong>Connect wallet</strong></a> (Base) to join {tokenData.icon} <a href={getTokenViewOpenSeaUrl(tokenId)} target="_blank">Community Garden #{tokenId}</a></Style.ConnectWalletArea>
  )
}

export const GardenDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between; /* This will space out the child components */
`;


export const GardenDetailsLeftColumn = styled.div`
  //background-color: blue;
  flex: 2;
`;

export const GardenDetailsRightColumn = styled.div`
  //background-color: red;
  flex: 1;
`;

function GardenMints({tokenData}) {

  const MAX_TO_SHOW = Math.min(tokenData.totalSupply,15);

  const [showAll, setShowAll] = useState(false);

  return (
    <>
      
      {showAll && <>
        {tokenData.mints.map( (item, index)=><GardenMinter key={index} address={item[0]} tokenData={tokenData}/>)}
         {Array(Math.max(0,tokenData.totalSupply-tokenData.mints.length)).fill().map((_,index)=>( <p key={index}><GardenIcon tokenData={tokenData}/> <em>Available</em> (#{index+tokenData.mints.length+1})</p> ))}
      </>}

      {!showAll && <>
        {tokenData.mints.slice(0, MAX_TO_SHOW).map( (item, index)=><GardenMinter key={index} address={item[0]} tokenData={tokenData}/>)}
        {Array(Math.max(0,MAX_TO_SHOW-tokenData.mints.length)).fill().map((_,index)=>( <p key={index}><GardenIcon tokenData={tokenData}/> <em>Available</em> (#{index+tokenData.mints.length+1})</p> ))}
        {tokenData.totalSupply > MAX_TO_SHOW && <p><GardenIcon tokenData={tokenData}/> ...<a href="#" onClick={(event)=>{event.preventDefault(); setShowAll(!showAll)}}>more</a></p>}
      </>}

    </>
  )
}

function GardenIcon({tokenData}) {
    return (
      <span style={{color:tokenData.iconColor}}>{tokenData.icon}</span>
    )
  }

function GardenMinter({address, tokenData}) {

  const { data, isSuccess } = useEnsName({
    address: address,
    chainId:1
  })

  function abbreviateEthAddress(ensAddress) {
    // Regex to match the part before .eth
    const match = ensAddress.match(/^(.*)(\.eth)$/);

    // If the regex doesn't match, or the address isn't valid, return the original address
    if (!match) return ensAddress;

    // Extract the part before .eth
    const name = match[1];
    const suffix = match[2];

    // If the name part is longer than 8 characters, abbreviate it
    if (name.length > 9) {
      return `${name.substring(0, 4)}...${name.substring(name.length - 4)}${suffix}`;
    }

    // If the name part is not longer than 8 characters, return the original address
    return ensAddress;
  }

  return (
    <p><GardenIcon tokenData={tokenData}/> <a href={network.getAccountUrl(address)} target="_blank">{(!data) ? abbreviateAddress(address,4) : abbreviateEthAddress(data)}</a></p>
  )
}

function Garden({tokenId, tokenData, refreshTokenData}) {

  const [prevTokenData, setPrevTokenData] = useState(null);
  const [nextTokenData, setNextTokenData] = useState(null);

  function getPrevNext(tokenId, arr) {
    // Find the index of the item with the given tokenId
    const index = arr.findIndex(item => item.tokenId === tokenId);

    // If tokenId is not in the array
    if (index === -1) {
        return { prev: null, next: null };
    }

    const prev = index - 1 >= 0 ? arr[index - 1] : null;
    const next = index + 1 < arr.length ? arr[index + 1] : null;

    return { prev, next };
  }

  const [allGardenTokenIds, setAllGardenTokenIds] = useState(null);

  useEffect(() => {
    setPrevTokenData(null);
    setNextTokenData(null);

    fetch(`${serverUrl}/gardens`)
      .then(response => response.json())
      .then(data => {
        console.log("all gardens", data);

        const {prev,next} = getPrevNext(tokenId, data.enabled);
        console.log(`Current tokenId: ${tokenId}, Previous tokenId ${prev}, Next tokenId ${next}`);

        setPrevTokenData(prev);
        setNextTokenData(next);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [tokenId]);

  function removePaddingFromHTML(html) {
    return html.replace("#matrixOutput{shape-rendering:crispEdges;padding:65px}", "#matrixOutput{shape-rendering:crispEdges;padding:0px}")
  }

  const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
  const isNew = tokenData ? (new Date() - new Date(tokenData.createdAt)) <= oneWeekInMilliseconds : false;

  return (
    <div>
      {tokenData && tokenData.error==null &&  <>

        <GardenVisualArea>
          <Style.HiddenMobile>
            {prevTokenData && <GardenNavIcon><Link to={`/token/${tokenId-1}`}>{'←'} {prevTokenData.icon}<br/>Garden #{tokenId-1}</Link></GardenNavIcon>}
            {!prevTokenData && <GardenNavIcon><Nbsp/><Nbsp/><Nbsp/><Nbsp/></GardenNavIcon>}
          </Style.HiddenMobile>
          
          <GardenIFrame srcDoc={removePaddingFromHTML(tokenData.html)} title="Embedded HTML" width="100%" height="300"/>
          
          <Style.HiddenMobile>
            {nextTokenData && <GardenNavIcon><Link to={`/token/${tokenId+1}`}>{nextTokenData.icon}<br/>Garden #{tokenId+1} {'→'}</Link></GardenNavIcon>}
            {!nextTokenData && <GardenNavIcon><Nbsp/><Nbsp/><Nbsp/><Nbsp/></GardenNavIcon>}
          </Style.HiddenMobile>
        </GardenVisualArea>


        <GardenDescription>
          <p>
            <strong><GardenIcon tokenData={tokenData}/> Community Garden #{tokenId} - {tokenData.title}</strong>
          </p>

          <p>"<em>{tokenData.description}</em>"</p>
           <Style.Break/>
          <p><Link to={`/view/${tokenId}`}>View</Link> / <a target="_blank" href={getTokenViewOpenSeaUrl(tokenId)}>OpenSea</a> / <a href={contract.etherscan} target="_blank">Contract</a></p>

          <p>  <Style.ShowMobile>
              <Style.Break/>
              (
                <GardenNavMobile>
                {prevTokenData && <><Nbsp/><Link to={`/token/${tokenId-1}`}>{'←'} {prevTokenData.icon} Garden #{tokenId-1}</Link><Nbsp/></>}
                {prevTokenData && nextTokenData && <>|</>}
                {nextTokenData && <><Nbsp/><Link to={`/token/${tokenId+1}`}>{nextTokenData.icon} Garden #{tokenId+1} {'→'}</Link><Nbsp/></>}
                </GardenNavMobile>
              )
            </Style.ShowMobile>
          </p>


        </GardenDescription>

        <Style.Container>
          <Style.CenterColumn>
            {/*<GardenTokenIconsRow>
              {Array(tokenData.numMinted).fill(1).map( (blah,index)=> <React.Fragment key={index}>{tokenData.icon}</React.Fragment> )}{Array(tokenData.totalSupply-tokenData.numMinted).fill(1).map( (blah,index)=> <React.Fragment key={index}>{`-`}</React.Fragment> )}
            </GardenTokenIconsRow>*/}

            <WalletInterface tokenId={tokenId} tokenData={tokenData} refreshTokenData={refreshTokenData}/>
            <Style.Break/>
            <Style.Break/>

            <GardenDetailsContainer>
              <GardenDetailsLeftColumn>
                <p><strong>Onchain Garden Info:</strong></p>
                <Style.Break/>
                <p>{"▰"} <strong>Token</strong>: <a href={getTokenViewOpenSeaUrl(tokenId)} target="_blank">Community Garden #{tokenId}</a></p>
                <p>{"▰"} <strong>Title</strong>: {tokenData.title}</p>
                <p>{"▰"} <strong>Release Date</strong>:  {new Date(tokenData.createdAt).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })} {isNew && <span><strong>*NEW*</strong></span>}</p>
                <p>{"▰"} <strong>Last Updated</strong>:  {new Date(tokenData.lastUpdatedAt).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</p>
                <p>{"▰"} <strong>Type</strong>: Onchain HTML (ERC-1155)</p>
                <p>{"▰"} <strong>Chain</strong>: {network.chain.name}</p>
                <p>{"▰"} <strong>State</strong>: {tokenData.burnable?"Burnable":"Unburnable"}, {tokenData.editable?"Editable":"Uneditable"}</p>
                {/*<p>{"▰"} <strong>Description</strong>: "{tokenData.description}"</p>*/}

                <Style.Break/>
                <Style.Break/>
                <p><strong>Mint Details:</strong></p>
                <Style.Break/>
                {tokenData.merkleRoot != MERKLE_ROOT_NULL && <p>{"▰"} <strong>Mint Status</strong>: { tokenData.numMinted == tokenData.totalSupply ? <MintStausMintedOut>Complete</MintStausMintedOut> : (tokenData.mintable && tokenData.numMinted < tokenData.totalSupply) ? <MintStausOpen>✔️Whitelist Only</MintStausOpen> : <MintStausClosed>Closed</MintStausClosed>}</p> }
                {tokenData.merkleRoot == MERKLE_ROOT_NULL && <p>{"▰"} <strong>Mint Status</strong>: { tokenData.numMinted == tokenData.totalSupply ? <MintStausMintedOut>Complete</MintStausMintedOut> : (tokenData.mintable && tokenData.numMinted < tokenData.totalSupply) ? <MintStausOpen>✔️Available</MintStausOpen> : <MintStausClosed>Closed</MintStausClosed>}</p>}
                {tokenData.merkleRoot != MERKLE_ROOT_NULL && <p>{"▰"} <strong>Merkle Root</strong>: {abbreviateAddress(tokenData.merkleRoot)}</p>}
                <p>{"▰"} <strong>Supply</strong>: {tokenData.numMinted === tokenData.totalSupply ? "✔️":<GardenIcon tokenData={tokenData}/>} {tokenData.numMinted}/{tokenData.totalSupply} minted</p>
                <p>{"▰"} <strong>Price</strong>: {tokenData.price > 0 ? `Ξ ${tokenData.price} each` : "TBD"}</p>
                <p>{"▰"} <strong>Max Per Wallet</strong>: <GardenIcon tokenData={tokenData}/> {tokenData.countPerWallet}</p>
                
              </GardenDetailsLeftColumn>

              <GardenDetailsRightColumn>
                <p><strong>Planted: ( <GardenIcon tokenData={tokenData}/>{tokenData.mints.length}/{tokenData.totalSupply} )</strong></p>
                <Style.Break/>
                <GardenMints tokenData={tokenData} mints={tokenData.mints} icon={tokenData.icon} iconColor={tokenData.iconColor} totalSupply={tokenData.totalSupply}/>
              </GardenDetailsRightColumn>
            </GardenDetailsContainer>

            <Style.Break/>
            <Style.Break/>
            <Style.Break/>
            <Style.Break/>
            <Style.Break/>
            <Style.Break/>
          </Style.CenterColumn>
        </Style.Container>

      </> }

      {!tokenData &&
        <Style.Container>
          <Style.CenterColumn>
           <Style.Break/>
            <Style.Break/>
            <p><em>Loading <strong>Community Garden #{tokenId}</strong>...</em> <Style.RainbowSquareStyled>■</Style.RainbowSquareStyled></p>
          </Style.CenterColumn>
        </Style.Container>
      }

      {tokenData && tokenData.error &&
        <Style.Container>
          <Style.CenterColumn>
            <Style.Break/>
            <Style.Break/>
            <p><strong>Error Loading Community Garden #{tokenId}</strong>:<br/><em>{tokenData.error}</em></p>
          </Style.CenterColumn>
        </Style.Container>
      }

      
    </div>

  );
}

export default GardenView;
