// autogenerated file at: 10/13/2022, 8:22:31 AM

export const lastUpdatedTimestamp = new Date(1665674551997);

export const cleanedAddresses = 
[
	// tiny based frog: 
	// https://basescan.org/token/0xf0d0dF7142f60F7F3847463A509fD8969E3e3A27#balances
	"0xcb61307ca6e803e3294afa9c1a77a20340f11316",
	"0x32044b9d572d61602eeb3b6af5c7bfcdb8bb8c32",
	"0x0ebbac9817029d4b302734b15b97be1a02eb9b60",
	"0xe6c3a76c20beeafc5e6ec1cc1542ac40a02306a6",
	"0x95d2424b94f26a42de7575ec8dce88e1cb52624b",
	"0xd6bbc0a9dd616c806e05e281955982f2f561bf3b",
	"0xd1f22cdfd36e57c8e49aea00d6662001ce2368ec",
	"0x97da20dc2bd46174da79d71a385b7248527fbee6",
	"0x2095098dac6eec457572665bf22dc7e59439c8f5",
	"0x55a0730a844727bd694fd8f938a456158be0f8a6",
	"0x8f8282bfc24833d4dfb0d5e3d916a31fd962ac6d",
	"0x85cd27925bb35feb2a1199e17e7d94c51c44f28e",
	"0xffdd4b4c33ac821c376cc336d1888d5900cf65c9",
	"0x5f00875ab6942bd586dd23dbbd79af6a801527a4",
	"0x179318748b758d6c99a851f10f6667c998da9358",
	"0x3d889b6391506032f9e9380a8f5cd3199661fb72",
	"0x75a88b65ca71f685ec2ac93ded002305240b79da",
	"0xa10f8a28bd727f227f4113ae5df5d3c5b9537ad0",
	"0xc20ec21380e99b146e6dc2bd2ee7e204f3ff89bb",
	"0xd2358dc28a6d7a9f1ebb59f96a2215aa2a432822",
	"0x6552316697951435772137032762628463705058",
	"0xb580ac340a9ca1eecf248b3418400803c827f437",
	"0x0a76ce3ffa3a674298fcdb23b8858691a96bae03",
	"0x0ae358222ed5bd9bd73137bdcfed9319c9ad69c5",
	"0x0c4f5dedbf29dcb6db59f9e0badc84dfbcd06e32",
	"0xb6a3dcc21e999696ed02548d773513a16a6bf5b8",
	"0x33ee32fd704d2f145fbd5a26f1871663c232b24b",
	"0x4976be42c5990c97db914e7285c98029435368ad",
	"0x52ae96079530b311c7bdbe89b5cb07ab6facf67d",
	"0x56f2c4cb8518d0a4ec749593df574f7597a36140",
	"0x9061b1c3c257e0bec68c210fc15e4f4ee27ecb3c",
	"0x06c1a3b6d3d4752e45d74f93e25e9ebd1f087f55",
	"0x097da8e483b747541ffc26e8b00b59ebb4a6d77f",
	"0x1bb168cafc4422cf67445d1eab6879a06fb1aff7",
	"0x3d43c9553da483e69e2bd3175b4b899b30c877ed",
	"0x4b0251f6f31c3a337ea27ca4c360cb169a239874",
	"0x820a74064213d6e0ef050392a5f32806ab887c0c",
	"0xc66ce6f7bf8ebd8fba3f099256da7d96fd3c8be8",
	"0xe7b9356d40c7b429cf2fe227ebea9908dafc9006",
	"0x003f35595dce3187b4fff2b5a2c4303f7158208a",
	"0x0962157ee073cce302428bd97ca285460cb923c7",
	"0x232e03cc440ad5158bd38636607f0e0ad62a01c2",
	"0x2fe9bc7790d1e9a0ddf9dfc002cd8ef2ca35d1c1",
	"0x32c7ea3efc7b64a0d38e516aa5e604ee2de17dc3",
	"0x380e5b655d459a0cfa6fa2dfbbe586bf40dcfd7f",
	"0x4da1c12022b211b636583f2e76ac1f0822ead364",
	"0x7097ef1fbaf7ff3eed5ae1b6ff22ab4bbe719331",
	"0x735fd95e5be6a284c44aaee2936cd09fb8fff5e8",
	"0x74a5bf60edc1d2eef5fb85ba6879d084ec11bcc8",
	"0x83d077537b49cef31eff6ec96bc053836039843f",
	"0x95f2ceadfa5ab15d22899c5ba675343994c250fa",
	"0xba00d84ddbc8cae67c5800a52496e47a8cafcd27",
	"0xbc093f7b8913d61f0b285e2e8dc6ac6486f590d4",
	"0xd4eed0069e6e6675b5a83d949638910ef583c822",
	"0x0f6e488f9ba936a643c16ef08db97f92055bec14",
	"0x12983e740a0945b3ab10d3b48a69bfffdbff1396",
	"0x1f2f8f9f1e397f51e28e09af8ce481342580cca5",
	"0x4930d3d0a85848f25b6f89e99708909a82f853e8",
	"0x5088c8043d495bfb51de9edf2913e0db30b7c7c3",
	"0x8062d427e974400522eee58d24a37bbe0c461d66",
	"0x8d6dc3713c4cb90f442ccd6450e8b3c36d0ea342",
	"0xa17138c0675173b8ea506fb1b96fa754bc316cc2",
	"0xb15922f4a9213d8df55e84a1832a893426a3ae40",
	"0xfa5c776343c4b64abfe939f2616acd2d1a257905",
	"0x11270bb15d07a658ee379236c005439e7131a25a",
	"0x21952852081ddbf42bfbe040c472444b5e10e5d4",
	"0x296967ca2e9b40f75d91662441609d1931d94615",
	"0x3e1d053f10297bed5bb0f077214055012338680e",
	"0x4001b76023f187eb1a4dd137e21e0f2fb686bab2",
	"0x50f27cdb650879a41fb07038bf2b818845c20e17",
	"0x56e14c505b2576f166cb944b31df1eec5c8a3aef",
	"0x61083772b5b10b6214c91db6ad625ecb24a60834",
	"0x6bba2e8057feeb65cd78c1c7987a904fb7a01e36",
	"0x6c1ddfb81a3666188267296f10253a5a9b5bae40",
	"0x711281c1b26aaed86e40e4caaf76c1962b45e161",
	"0x799e96304316b23489136e9dd28129bcd203749f",
	"0x7eb6dd4a0b074f405ac5d70afd13c0d56cb497b7",
	"0x8443c9bacb44381e962e7081da9b5f4a4ebbe521",
	"0x8aed25a73b98030725e923d3db570f8e72c3c51b",
	"0x8b4100f8802c9cbbaeb58e68d4dda355561560d1",
	"0x9c62d9d5299fed9ca92eef64cb3fddbb0acad0bd",
	"0x9cdf0dcc2dabec4f3971448607489c43d66b8dfa",
	"0xa2501eab283a0ea851fbf9d02cbc298fee8a1f62",
	"0xa3240f5552cd1a1ccd539b116766c66b4ea09dee",
	"0xa8c90749ee4fdf4089ac4303ad8d414d34484896",
	"0xb8cdce30204057e90300ec3cacc30d0296636788",
	"0xd4831db3ed2b6950451dd9e9d668f1575370e5bf",
	"0xd68c599a549e8518b2e0dab9cd437c930ac2f12b",
	"0xebf0dca5b9e9fef10893edec36237b405718dd05",
	"0xec5f6247e8919283be249b89b30aae540a3035f3",
	"0xee45b9e877c04c287931be6133fae9e95db661aa",
	"0xf5851672ab7fc8729de7472dd273066b5e3b4de5",
	"0xfff674e7f386d9e4b50e287ad293168dfc8718ec",
	"0x00d06d6be25e8407e3563bbf98350744b48ae8d0",
	"0x0d02ed24ef53c868ef407b266178aa162775569a",
	"0x1a02a60113212dfac4f8b78849559e6fc3527464",
	"0x1fdbffd0d8e237e64e68a904c2a6f447a1ad5c90",
	"0x21b7e18dacde5c004a0a56e74f071ac3fb2e98ff",
	"0x22a676b52392591bc6c7caf51c14b38f5e0f3716",
	"0x240e2cb5eabb13b89d39acae9a5a54b3fe523298",
	"0x2d5c45be1239ef64af5bc04a1ca92ff4f6bf45a2",
	"0x34c80290e0e0da20259cc8128370783b21ac9b31",
	"0x3881f0735d9c8aaa779fce6f72938c52e424f852",
	"0x40f465f1ba4c2aba91c0c896cb92bbe4c7e545df",
	"0x443c7c05369e35ba4256c8f486274096e0540d9b",
	"0x49c6fb6cbe27ce0e773b85840cb072791368e5bb",
	"0x4abf2123bcddce3f853066645ae2e0e1c1a7d0e9",
	"0x547a2e8d97dc99be21e509fa93c4fa5dd76b8ed0",
	"0x62a89b3b52d5a5c234d15481f7c2ed16e4b8888b",
	"0x694367ea528240fec8172581282ff171a6ec39aa",
	"0x6c09dc80cb7db6a8bdea2463eef2a1128b8c71e7",
	"0x7196ba6d54ad7552a4fa69630a5ea76286e1bbb3",
	"0x74bb4995d5f1302b55b14bf6c1df9eb39e3f57ce",
	"0x75256a46e98eb0f9c4eefc197eb3dd88d559a771",
	"0x7c049996613ad3673f3edacbce58faf000a0b580",
	"0x7da0f2b3a059e2d6a74e30539e11d5631568c8d9",
	"0x83293bdfc3cc47cc2b4ddb4b5b710e0e0791d100",
	"0x840a7c68ba7458184fe2686e7a0d5afeb0aeb282",
	"0x84b0c50849d29636132924ffd375bd5edb721caf",
	"0x874fc12eb80efee30efad4886671044db6259fca",
	"0x8bace3a49a375027868cdd34e84521eed1f1b01d",
	"0x9159059c589881a69ca3a29289062e000c621b59",
	"0x95e5115ac31f61bdcb1de579fe0c8c745f99926d",
	"0x9832a8dd9a38b27c12a564e2362c581cb5f5bd2b",
	"0x99a2caf816d54549365aba70b41b5b426be2e637",
	"0x9e1f42c1c9443ddcd59adc7a5aaf82164d959ffe",
	"0xa1922c47aa67c41b1c1e877e9919f5ef29c99373",
	"0xab98594140d7dc21070fbe4106da840bdbe7494e",
	"0xaeadc05d4b8776e4d82f8243b76b60664fdfe4ab",
	"0xaebcc707edac413af8782c5bb1a29a6b2d904719",
	"0xb0439b8422ce630b3b897f8bf559e30df1a0c94d",
	"0xb42691ccb2257b3f03665d229219ecd6bc9f6189",
	"0xb9dbb7cca741fd120831529489aa014c4a1aeb04",
	"0xc0452507b41d2da08a1630394d064b45617017c6",
	"0xc2b229bb3372399f85d628a21a99e4b4fa8a25e0",
	"0xc6376540cf443d22cc3c697526bee1f81401e61c",
	"0xd614cf3fced0e10fb95c3749dc48b92cfd4d54c4",
	"0xdb743efca1795bfd472c2114a189c267f0cae21e",
	"0xde4b72239f6d6e2342cbc48ca8fb04e05a25f1c7",
	"0xe844b9b5d9bb5f874434960c9e32a678ae327577",
	"0xfb2285df9dfbb69188ee8fab3ebf23422a3910a6",
	"0xff51712c8f5b7108d1896b94ce5c183659b82ef7",
	"0xffcdcf517f40ec98e6035f86a30fd8ad3abf67dc",
	"0x0000000000000000000000000000000000000000",
	"0x010d004994113122fe3d8c59398b8490f72016cf",
	"0x015927d8f90d51bf7e2e5b8aa82930b5a5e87641",
	"0x01c2f6e624e5b29b5dfb83871afc2b7961510aa4",
	"0x0291687811ae3020ded4a2127096eba0c30db6b4",
	"0x03757f2b37e67481f33b24c12cfe60e773472869",
	"0x046d02794dd42da67ac95010404c61df3efc7bd9",
	"0x06ee07a1591f2d8449516ef167acc9bf0b968103",
	"0x0a591f038c4c576346daa4f0b50edb80f5ccb635",
	"0x0b1d7713a5a0e7495fbaba84415455ebba1ae484",
	"0x0d2637f4b304e4d2a86078eef60efcf4daf1168d",
	"0x0ed76e7e13522358341834bed8bb4577bdeeb12a",
	"0x0ff03e5fbab870a80a8beb54ee43a259c004e8a1",
	"0x13eee41d67b8d99e11174161d72cf8ccd194458c",
	"0x151e9e24b3220541364711ab4f51cb01e5760d2c",
	"0x16263c672839150618714d6e167b61e1307f9d84",
	"0x1675e60b87efcd688de3e00e79739be750330067",
	"0x168fef2fd1af371625b2593468620185cea904ff",
	"0x17d8e3268b7c81111c98b7d3ccea55072a32d6c0",
	"0x1c223f9183a753a5b5303e5131bcc067b149566c",
	"0x1f3ca34e78ccb5581807b51915b0afe304a92450",
	"0x1f77d34deff2b72b0a0258603effe72704742ebb",
	"0x219688c6156edea2e2ca7e2036c1c5b7f6451169",
	"0x21bc709ad5310a86d46554da9bfcff9ebb1afba1",
	"0x21e32405336774f24c29bf1d41629c65dde2aaed",
	"0x29a8a3d7a1b476dce0d469d3111009aa00bac43e",
	"0x2ace5eda1242badc322ce2a821ea9e309de63c53",
	"0x2b243d03e46887bf8894e77e632bcbf47b36a123",
	"0x2b70f6d408c2eb9bf2a731ee77047d4fe6c43a42",
	"0x2d192ea713758ef0ff436d7d3582033b462cc1b6",
	"0x2d7d5a9ad74f16d310ab59dc676e9978a1ceda02",
	"0x2f59ec271bb23fcb88a01328ead107b5a9f7b75a",
	"0x30f5b02e8867763abaef9dcd3beb8c7f0eb8aaa2",
	"0x31ce44f7a8a92208dfcbfca3358de9b70976a528",
	"0x31f7f2aa424e34b0373029851d6acb532eef71d3",
	"0x324fb8fe0c9f09c671b337c91c17e7f36cfcabc4",
	"0x32b55b591eead7600a241806f899cb1e6bae99b0",
	"0x36d4fb547def8696138dad1798c3275a61380e94",
	"0x36e939f9140c41377add0d3b5d22915ad3619e75",
	"0x37088a716b3097ca0a9801c590cd6c086ac1e6eb",
	"0x3776e8a0746661e330b881bc1327accc2054bd5f",
	"0x377abba00a7740b7166fec0a6de04992290ab7d7",
	"0x3b6fcd7a9cfa4646e182acde65d9753652328c8c",
	"0x3d21175ad1a18a262072e825ca9ac9cb962c8e0d",
	"0x3db0de0eacdcd7318d522a420c3b532335d048f6",
	"0x3ed2568a417cadab4e5e0552439f3baa6346636f",
	"0x4193736954b08006bf4deeec9771ddaf2a624309",
	"0x41e9cfecd34d345a4e7ff948a089a15fc6c50897",
	"0x4206c3d5083460411e76dbd30504fec3b56d387a",
	"0x42f2d682d9cee89a98347768337938ed8d18c266",
	"0x433219bf17ee5b210d472270d9e0eb6a57ae5fb2",
	"0x43d806a32df954ae9b2af6724b4b8bde0b8f51d9",
	"0x44651bd9dedea84e1379b8e062a533bd307db248",
	"0x44a807967d48ca4ed5f2dea888ce5c1f32faef9d",
	"0x44bd3de32ea166e90d714a6cd60d96d9748de65d",
	"0x44e9d011df8741be2493e99297e9ad67bb1aa85b",
	"0x45180317b52d63077e8ed0c36073c6bf8429a876",
	"0x45a1d203ecbdf1eb63ebcf6397ab8201c8177b10",
	"0x45e48c0a6fe8b759652624451c83387130c58367",
	"0x4d1baafb2be10ded7405cc71a8c6bec421fbda9a",
	"0x4f67f52147c6bc03563772fa3d7af3adffb92110",
	"0x521960fe86a1b5ed559dd8da92ac14bc50344f89",
	"0x538df212def9d27b646b733cb267a69cbe1b77ad",
	"0x53c38834e39bb21694c8617c165f90b58706422a",
	"0x5607aff8fda34dff02d91672097810bfd3f7e6ad",
	"0x596a3ae8739a9f3315a4a8f02169a704a099fcf7",
	"0x5be89b432b873f8c1ca180c16f225674ec53a19b",
	"0x5cd3a623afa7e732c303f57253119a17bd571f1d",
	"0x60bf43108309a39bc3df0d6c9294a18753bf1cae",
	"0x61240103d0ef3bf2b6f34513eaf8cdd96971e8d3",
	"0x612bed2ef6a0b7916e098a2d055f4b483ae2d569",
	"0x624be54fa569ad1c6e6ca27977e960f8b4fb6b7f",
	"0x627ca7601e943cbffd21aeeb7bb06b9a3137b0ec",
	"0x628bef98e2d735c05394140671e899fe2df436b7",
	"0x62afb14257fa70b265a8e6c6dc8beafd7091d0cb",
	"0x62f4f976174225e8f3a9c218077664dfe72cf388",
	"0x6344fee7c76c52f21986cb02897ae43c0bcbe4df",
	"0x644e357b0dc7f234a1f0478de7fe8790b94b6f63",
	"0x6459ea73a47c4153a608957f46f44645d0aaaf9d",
	"0x6469b96f582ee2e09ec64b81bbceffeb02d0c2d1",
	"0x65c76a684dbfd773bab8a7463e7498686bafd833",
	"0x6717ba62c488b11e74e70343cb57485302e8be79",
	"0x67ba79f073e9f7e56e73f47b515a123fce92a870",
	"0x6e4ec7d1e6fdc5bfecfcf8cfa5024359b7b0221a",
	"0x70ddda43d4f616263b1db0ec672cbd8795db13b1",
	"0x70de37fd37b43ac5beb9c20e9d4af5bbdc62feb9",
	"0x724f49d5f2b323801b86819b726d3364bbec2401",
	"0x733442f5f472bc7f7de23679a1156aae9a49946d",
	"0x746006a055fe643b66ad733356545abeca85a37e",
	"0x758e6bd3b50228956d72f49c5c5dfb62461ec846",
	"0x76963b82e10075dd6a61fb0556fce5daa5aa4eb7",
	"0x76d3836aa6e083f2f3cf7eb6b38931943b882a36",
	"0x76e5db63bf8f1f22886ebe88fb4ed6859b36b6cd",
	"0x7866d20529ab2d70fe3c5a8384905fa5e811b813",
	"0x7ad252facf5f1115b22ea0a4d63e8770573d78a2",
	"0x7ade5c954a8ccce4541424c6be3da6073b14e81b",
	"0x7e3e8e243bd07918f996f7c42aa0b14fb7dfe0c5",
	"0x7e81462e3c1a43cd4bff20216ac5ebeb6913e534",
	"0x7f69761c710ad494656e0364c82f014e88e8b3f7",
	"0x8125ef62932875f3dfab6c9b39fa12c087397cb5",
	"0x82df8f78d01b9b714744900a6c7c0ca1eea11af0",
	"0x83ea21c1ce785e4656b5ca65f97d87649c687d07",
	"0x85c7cabc0ad3d57dc7c3b1467f74f3c2baa5f268",
	"0x879765ffd7f2fe105ed3aa42b15b5f5ad241f0bd",
	"0x8810dff59abbc5cca0332a31befdfac67f62b058",
	"0x88368732e79ad01299025e6216bee288d2595d2a",
	"0x8aa75cdeaf76980fbff0f2a838cb5a77a847ecb2",
	"0x8c8e23fa04557d9e0b579ea5fe45051e934f147d",
	"0x8fed5a4935eaa22454c7a5383ba9eab33da2850e",
	"0x92455092cdeeafca0446a7be63f36ba35436014f",
	"0x93a521a4c6880b290cab43d192c35058634b00fc",
	"0x9437407f51052176c63b9fe5a32f54b6f12c21ad",
	"0x94c9fe206ea43185e3de2a42d7b6fb91bb5622bf",
	"0x95e85dbb74414f1c30c34172519760bbe44840e8",
	"0x96fca82bb2ce4c5a700a14581412366cc05dd6fa",
	"0x972578e8d981cebc6ecdc822cb663f6548c4a963",
	"0x992007e4e23949e54b13d34e4ae27eb406ce351e",
	"0x9d677028e0e592d48fc8a8ddc910693301a4a450",
	"0x9f138d8de583dbc277b3e389fcf37e4709d8620b",
	"0xa0d36a1b10abc9a6a23c36052af8c2a708c33f36",
	"0xa1cca97078763789d6cbd296f82c53bf1ee2858a",
	"0xa1fa4ad9e365e1f491fde46df2c6b7b7d9a5d30e",
	"0xa260cf1726a6a5e0b7079f708823fc8e884611cb",
	"0xa2b2f10a3c542fed3a53654cca97d34f628f55e3",
	"0xa478e7a8792b584845cfe27819761e16422154b4",
	"0xa499a4a5b48cf4f5ec58ce9cce28db4e6ea77653",
	"0xa92124622bba8fa904e3e9095e4fd32d3092d25c",
	"0xa9537b1034e94f4bd41855e5006b68ae4368e6e6",
	"0xaa23395b6deaddb0a6ef6ef2c09fe7cdbc55dff1",
	"0xaec03c9802ea8baa3c1f204509096eb0d2818a3b",
	"0xb04ab323d08db5eb3e8b6b17aeddc6499bedf40b",
	"0xb1c6bcd66322e1d21ac1bd71ffc6b570a1e67b80",
	"0xb293be1098a24772cad55c683e1e10e082732ff3",
	"0xb404dd805ef19f74a03b3603af299156bc366c39",
	"0xb4d96b8e0f8fc108b4357bf4d302d8f63918f627",
	"0xb78994cb15815d77a0320dbe8196f113043e70d1",
	"0xbb2fb9b7020a17940981adee63ea2f1876188888",
	"0xbc0c19235d263aa6ffe8a4dec7901a343539d7e1",
	"0xbc9c6379c7c5b87f32cb707711fbebb2511f0ba1",
	"0xbd7dbab9aeb52d6c8d0e80fcebde3af4cc86204a",
	"0xc11e79ddaa2229252904d889cc97cc35fab20d45",
	"0xc4dc2ee6b0c68e8f923d2d40fd215b83171393ee",
	"0xc5c096a642bab3a435485f4e1864df179670d4ca",
	"0xc6ea75708c9a352b73a5cdaad144693969780ef8",
	"0xc81020524e06e79c3e80de814bdab78c7bade1eb",
	"0xc9a344a7cf253bfc2d5722384bb79e75594ece3d",
	"0xce35355a60603c5f4e053b44f4a8d8286dc41ddc",
	"0xd2bc652d477bddf6e1d8551a76d386423d4ebede",
	"0xd2f5b5fecc86b02f1a77823fb96a7052c62571d1",
	"0xd33e8e9b758765b7652e99ac51b3f145c900b5ea",
	"0xd35398bcc5c74f42e8d2d1bf63c983c9762fa33a",
	"0xd7ed008295e7ddd477a557e7646525e2cdfbb9be",
	"0xda3bb3dccbc85cf8a6560064254ffb49e558af68",
	"0xdd657d10811e26097e3a78a447264f1efa2b3524",
	"0xe0bcbe59bb04bec47cba0af01d2086de967c0279",
	"0xe16c0319ad0e63c1c754df7419a3c40fc84e3687",
	"0xe1e7ba7f42a262069b8f296be7cbf9bff96f4ad8",
	"0xe25b6d080af358126a23a341d3aace7324caedb2",
	"0xe57afa3af12bce0e4406c437f46850babf57364e",
	"0xe57cac0f857a3a44c9800ae42b5a56a64ad906b2",
	"0xe603c11017ebceffaa32084f9bcad508cbb0d133",
	"0xe7600af942b7ceb601bc5e6658532c7dcef923a6",
	"0xe89c7ff9d7c289fe7816cf7a36632d662b35c7f7",
	"0xe8d3c311912513ae9cc34016db69eae9e9f4e2cc",
	"0xe8fe28d94b256035d9dd13f20a71ccac66a6648d",
	"0xea65e1f3b04d55c65e51c2099200e0f569f69a79",
	"0xeb309f610be8569eaca8afab585c65c55de1934a",
	"0xeb8ca482ee0f547e9e3be84c36a3ae014e49ea99",
	"0xee4d308cc1cde3dd0adcb5a2c85edff22db64918",
	"0xf1bf8101c9ae2376aff79c50653f487c40fa5b84",
	"0xf2bb35a27cf41d2dbe0ebd712c78d4cf6265d198",
	"0xf39ceb8ab0de75dca31e988fd59d53cc009803e4",
	"0xf40879b72b12eb5acf49c6ef593c9e18fd7537ea",
	"0xf559d601a39d34554f29f107a395162833705b30",
	"0xf82d46e02dd50c280050d45f22d7e22aceb0f0ae",
	"0xfc2d2cca7b97658cb35b202715b3b02295bfa4ab",
	"0xfdb7a6812b64e8d4973c3068159bc08b6e1cb0fd",
	"0xff6146aad91a1a64000f7131624b02e764ecf1f8",

	// garden holders
	"0x097da8e483b747541ffc26e8b00b59ebb4a6d77f",
	"0x0a591f038c4c576346daa4f0b50edb80f5ccb635",
	"0x2095098dac6eec457572665bf22dc7e59439c8f5",
	"0x32044b9d572d61602eeb3b6af5c7bfcdb8bb8c32",
	"0x8b4100f8802c9cbbaeb58e68d4dda355561560d1",
	"0x9061b1c3c257e0bec68c210fc15e4f4ee27ecb3c",
	"0x9832a8dd9a38b27c12a564e2362c581cb5f5bd2b",
	"0xb1c6bcd66322e1d21ac1bd71ffc6b570a1e67b80",
	"0xd1f22cdfd36e57c8e49aea00d6662001ce2368ec",
	"0xde4b72239f6d6e2342cbc48ca8fb04e05a25f1c7",
	"0xe6c3a76c20beeafc5e6ec1cc1542ac40a02306a6",
	"0x07cd5843d74699f69b5780876eff93fc0ee858b5",
	"0x0d02ed24ef53c868ef407b266178aa162775569a",
	"0x21952852081ddbf42bfbe040c472444b5e10e5d4",
	"0x2d7d5a9ad74f16d310ab59dc676e9978a1ceda02",
	"0x3d889b6391506032f9e9380a8f5cd3199661fb72",
	"0x43d806a32df954ae9b2af6724b4b8bde0b8f51d9",
	"0x44bd3de32ea166e90d714a6cd60d96d9748de65d",
	"0x56f2c4cb8518d0a4ec749593df574f7597a36140",
	"0x62a89b3b52d5a5c234d15481f7c2ed16e4b8888b",
	"0x7e3e8e243bd07918f996f7c42aa0b14fb7dfe0c5",
	"0x8e05bd9fa3059ec69c15bc1a6f4d94f0ac26ce00",
	"0xa412e5f94cec412dd93f659836d92b160aa4531b",
	"0xb8cdce30204057e90300ec3cacc30d0296636788",
	"0xb9dbb7cca741fd120831529489aa014c4a1aeb04",
	"0xbec3231c3ea1b789847943e4adfd271d8c851a0b",
	"0xc0452507b41d2da08a1630394d064b45617017c6",
	"0xf613cfd07af6d011fd671f98064214ab5b2942cf",
	"0xfb005d80910d8ceee30ccef685135979ab5ad280",

	// other
	"0xE3F414db54873aBC3ECe1D23C6D5BB3673923A57", // deployer
	"0xc1e42f862d202b4a0ed552c1145735ee088f6ccf", // 🏆
	"0xa412e5f94cec412dd93f659836d92b160aa4531b", // 0xG (bm)
	"0xbE957A475844c127DDD207B4ff1F63900FD13E57", // sonoflastG
];