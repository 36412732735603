import * as Style from "./StyledComponents";
import {links, contract, network, hidden} from "../Config";

import { Outlet, Link } from "react-router-dom";

const Nbsp  = () => '\u00A0';

function Navbar() {
  return (
    <Style.Navbar>
        <Style.HiddenMobile><Link to={`/`}>🌼 Community Gardens</Link></Style.HiddenMobile>
        <Style.ShowMobile><Link to={`/`}>🌼</Link></Style.ShowMobile>

        <Style.NavbarLinks>
         <Link to={`/about/`}>About</Link>
         /
        <a rel="noreferrer" target="_blank" href={links.opensea}>OpenSea</a>
         /
        <a rel="noreferrer" target="_blank" href={contract.etherscan}>Contract</a>
         /
        <a rel="noreferrer" target="_blank" href={links.twitter}>Twitter</a>
         </Style.NavbarLinks>
      </Style.Navbar>
    )
}


export default Navbar;
