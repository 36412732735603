import { MerkleTree } from 'merkletreejs'
import keccak256 from 'keccak256'

// lower case
import {cleanedAddresses} from './_addresses'
//const cleanedAddressesLowerCase = cleanedAddresses.map(address => address.toLowerCase());
const cleanedAddressesLowerCase = Array.from(new Set(cleanedAddresses.map(address => address.toLowerCase())));

export function merkleRoot() {
    const resultMerkleTree = new MerkleTree(cleanedAddressesLowerCase, keccak256, {
        hashLeaves: true,
        sortPairs: true,
    });

    const resultMerkleRoot = resultMerkleTree.getHexRoot();
    return resultMerkleRoot;
}

export function merkleLeafHex(address) {
    return "0x" + keccak256(address).toString('hex');
}

export function merkleLeaf(address) {
    return keccak256(address);
}

export function merkleProof(address) {
    if (address == null) return null;

    const merkleTree = new MerkleTree(cleanedAddressesLowerCase, keccak256, {
        hashLeaves: true,
        sortPairs: true,
    });

    const leaf = merkleLeaf(address);
    const resultMerkleProof = merkleTree.getHexProof(leaf);
    return resultMerkleProof;
}

export function merkleCheckAddress(address) {
    if (address == null) return false;

    const merkleTree = new MerkleTree(cleanedAddressesLowerCase, keccak256, {
        hashLeaves: true,
        sortPairs: true,
    });

    const resultMerkleRoot = merkleTree.getHexRoot();

    const leaf = merkleLeaf(address);
    const resultMerkleProof = merkleTree.getHexProof(leaf);
    const verifyResult = merkleTree.verify(resultMerkleProof, leaf, resultMerkleRoot);
    return verifyResult;
}

export function containsAddressInList(address) {
    if (address == null) return false;

    const addressLowerCase = address.toLowerCase();
    return cleanedAddressesLowerCase.includes(addressLowerCase);
}

export function getAddressList() {
    return cleanedAddressesLowerCase;
}