// src/GlobalStyle.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    //font-family: monospace, sans-serif;
    font-family: 'Inconsolata', monospace, sans-serif;
    font-size: 12px;
    line-height:1.2em;
    margin: 0;
    padding: 0;
  }
`;

export default GlobalStyle;
