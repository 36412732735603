import styled from 'styled-components';
import { ethers } from 'ethers';

import * as Style from "./StyledComponents";
import {links, contract, network, hidden} from "../Config";
import Navbar from "./Navbar";

import {useState, useEffect} from 'react'

function abbreviateAddress(address, charsLength = 4) {
  if (typeof address !== 'string') return ''
  if (address.length < charsLength * 2 + 2) return address

  const prefix = address.substr(0, charsLength + 2); // '0x' + charsLength characters
  const suffix = address.substr(-charsLength);

  return `${prefix}...${suffix}`;
}

export const AlignRight = styled.p`
  text-align:right;
`;


function About() {

  return (
    <Style.Window>
        <Style.WindowTitleBar>
          <Style.WindowTitle>Overview</Style.WindowTitle>
        </Style.WindowTitleBar>

        <Style.WindowContent>
            <p><strong>🌼 Community Gardens</strong></p>
            <Style.Break/>
            <p>Fully onchain interactive community gardens + ERC-1155 tokenized social art experiment on <a href="https://base.org/" target="_blank">Base</a>.</p>
            <Style.Break/>
            <p>Each garden visually represents its members by planting their personal messages onchain, creating a collective expression of community. Mint a token inscribed with a personal message to embed into the shared ERC-1155 NFT.</p>
            <Style.Break/>
            <p>🌼 <strong>Collection</strong>: <a href={links.opensea} target="_blank">OpenSea</a></p>
            <p>🌼 <strong>Contract</strong>: <a href={contract.etherscan} target="_blank">{abbreviateAddress(contract.address)}</a></p>
            <p>🌼 <strong>Chain</strong>: {network.chain.name} ({network.chain.id})</p>
            <p>🌼 <strong>Token</strong>: ERC-1155 (Fully Onchain)</p>
            <p>🌼 <strong>Creator</strong>: <a href={links.twitter} target="_blank">@dailofrog</a></p>

            <Style.Break/>

            <Style.InfoMsg>
              <p>❤️ Supported by the <a href={'https://paragraph.xyz/@grants.base.eth/calling-based-builders'} target="_blank">Base Builder Grant</a>.</p>
            </Style.InfoMsg>
        </Style.WindowContent>
    </Style.Window>
  )
}

function ArtistStatement() {

  return (
    <Style.Window>
        <Style.WindowTitleBar>
          <Style.WindowTitle>Artist Statement</Style.WindowTitle>
        </Style.WindowTitleBar>

        <Style.WindowContent>
            <p><strong>Community Gardens</strong> humbly presents a tapestry of onchain communion, where every minted token contributes a verse to our collective digital poem.</p>
            <Style.Break/>
            <p>Within each garden, personal voices are sown, manifesting as tokens of individual sentiment, melding to form a shared canvas of collective imagination. </p>
            <Style.Break/>
            <p>"<em>If you want gardens, become the gardener.</em>"</p>
            <Style.Break/>
            <Style.Break/>
            <AlignRight><em>December 15, 2023</em></AlignRight>
        </Style.WindowContent>
    </Style.Window>
  )
}

export const FooterMsg = styled.div`
  text-align:center;
  text-decoration:italics;
  padding: 0.5em 0;
`;

function FAQ() {

  return (
    <Style.Window>
        <Style.WindowTitleBar>
          <Style.WindowTitle>FAQ</Style.WindowTitle>
        </Style.WindowTitleBar>

        <Style.WindowContent>
          <Style.FaqItem>
            <Style.FaqHeader><Style.IconLogo/> How is this project unique?</Style.FaqHeader>
            <Style.FaqContent>
              <p>
                <em>Community Gardens</em> stands out as a fully onchain, interactive generative art piece that visually represents its contributors by embedding their messages directly into the token. It leverages the ERC-1155 standard to enable a shared tokenized social experience, and to render onchain dynamic HTML directly from <a href={contract.etherscan} target="_blank">contract</a>.
              </p>
            </Style.FaqContent>
          </Style.FaqItem>

          <Style.FaqItem>
            <Style.FaqHeader><Style.IconLogo/> Who is the creator?</Style.FaqHeader>
            <Style.FaqContent>
            <p><em>Community Gardens</em> is developed by <a target="_blank" href={links.twitter}>dailofrog</a> -- a creative technologist and onchain ETH maxi focusing on generative art and innovative onchain interactive experiences.</p>
            <p>Previously, dailofrog has released <a target="_blank" href="https://www.tinybasedfrogs.xyz/">Tiny Based Frogs</a>, Base's first 100% fully onchain NFT collection (and currently #1 Base collection on <a href={`https://opensea.io/collection/tinybasedfrogs`} target="_blank">OpenSea</a> by total volume).</p>
            <p>View dailofrog's previous <a target="_blank" href={links.linktree}>NFT projects here</a>.</p></Style.FaqContent>
          </Style.FaqItem>

          <Style.FaqItem>
            <Style.FaqHeader><Style.IconLogo/> Is the art fully onchain?</Style.FaqHeader>
            <Style.FaqContent>
              <p>Yes. <em>Community Gardens</em> NFT is absolutely <strong>100% fully onchain</strong>, including the entire metadata and the interactive HTML artwork. This artwork will survive forever as long as the chain persists.</p>
              <p>This means there is no reliance on third party hosting solutions (i.e. IPFS or Arweave). The artwork is rendered onchain and generated on-demand directly from the <a href={contract.etherscan} target="_blank">contract</a>. We do this by having the smart contract emit a full HTML document during the token's metadata URI request.</p>
              <p>There are also no external graphics library used (i.e. threejs or p5js) in the renderer, just simple old-fashioned generative HTML served hot and fresh from the contract.</p>
              <Style.Break/>
              <p><em>We truly believe in the fully-onchain, decentralized, permissionless future of computer art.</em></p>
            </Style.FaqContent>
          </Style.FaqItem>

          <Style.FaqItem>
            <Style.FaqHeader><Style.IconLogo/> What inspired this project?</Style.FaqHeader>
            <Style.FaqContent>
            <p>Every few Saturdays, my daughter and I spend our mornings volunteering at the local community garden. Bathing in the sun, surrounded by nature and embraced by the community spirit, I'm deeply inspired to cultivate something collectively beautiful. </p>
            <p>Our endeavor is also profoundly influenced by past great onchain art experiments, especially <a href={`https://opensea.io/collection/terraforms`} target="_blank">Terraforms</a>, which has been a remarkable beacon of both technical and artistic inspiration for us.</p>
            </Style.FaqContent>
          </Style.FaqItem>

          <Style.FaqItem>
            <Style.FaqHeader><Style.IconLogo/> Why Base?</Style.FaqHeader>
            <Style.FaqContent>
            <p>We are confident in <a href="https://base.org/" target="_blank">Base's</a> growth as an Ethereum-aligned L2 and a key onchain cultural hub for future crypto enjoyoors. Its low gas-fees enable us to efficiently conduct on-chain experiments, including the encoding of large bytecode formats (like full HTML and art).</p>
            <p>Base has also graciously supported us via the <a href="https://paragraph.xyz/@grants.base.eth/calling-based-builders" target="_blank">Base Builder Grant</a>.</p>
            </Style.FaqContent>
          </Style.FaqItem>

          <Style.FaqItem>
            <Style.FaqHeader><Style.IconLogo/> How many Community Gardens will there be?</Style.FaqHeader>
            <Style.FaqContent>
              <p>We'll continue releasing new Community Gardens as long as inspiration strikes. Our aim is for each garden to offer a distinct visual aesthetic or interactive experience.</p>
              <p>Follow <a target="_blank" href={links.twitter}>@dailofrog</a> for updates on new Community Garden releases or view existing Community Gardens on <a target="_blank" href={links.opensea}>OpenSea</a>. </p>
            </Style.FaqContent>
          </Style.FaqItem>

          <Style.FaqItem>
            <Style.FaqHeader><Style.IconLogo/> What happens if I transfer or sell my token?</Style.FaqHeader>
            <Style.FaqContent>
              <p>When you sell or transfer your token(s) on the secondary market, your position in the Community Garden also changes hands and will be automatically updated in the collective onchain NFT's visual.</p>
              <p>New owners will have the ability to modify the message.</p>
            </Style.FaqContent>
          </Style.FaqItem>

          <Style.FaqItem>
            <Style.FaqHeader><Style.IconLogo/> Can I edit my token's message?</Style.FaqHeader>
            <Style.FaqContent>
              <p>Yes, if you own the token, you can modify the message onchain even after it is minted. This onchain edit is made possible through the <a target="_blank" href={contract.etherscan}>contract</a> by invoking the 'editMessage' function. Doing so will also update the token's visuals onchain.</p>
              <p>Please be aware that if the Community Garden is "locked", message editing capabilities will be unavailable for that garden.</p>
            </Style.FaqContent>
          </Style.FaqItem>

          <Style.FaqItem>
            <Style.FaqHeader><Style.IconLogo/> Can my messages get flagged?</Style.FaqHeader>
            <Style.FaqContent>
              <p>Your messages will always be stored onchain. However, we've added a moderation feature to address any inappropriate content.</p>
              <p>While we deeply respect the principle of free expression, we retain the capability to flag messages that cross acceptable boundaries for the sake of community standards -- such as bigotry, hate speech, harassment, etc.</p>
              <p>Just please don't be a dick, ok?</p>
            </Style.FaqContent>
          </Style.FaqItem>

          <Style.FaqItem>
            <Style.FaqHeader><Style.IconLogo/> Roadmap?</Style.FaqHeader>
            <Style.FaqContent><p>No roadmap, no utility. Just art for the sake of art.</p></Style.FaqContent>
          </Style.FaqItem>

          <FooterMsg>🐸🚬<br/><a target="_blank" href={links.twitter}>@dailofrog</a></FooterMsg>

        </Style.WindowContent>
    </Style.Window>
  )
}

function AboutPage() {
  return (
    <Style.Background color={"#98ed9f"}>
      <Navbar/>
      <Style.Container>

        <Style.ColumnLeft>
          <About/>
          <ArtistStatement/>
        </Style.ColumnLeft>
        <Style.ColumnRight>
          <FAQ/>
        </Style.ColumnRight>

      </Style.Container>
    </Style.Background>
  );
}

export default AboutPage;
