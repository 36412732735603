import styled from 'styled-components';
import { ethers } from 'ethers';

import * as Style from "./StyledComponents";
import {links, contract, network, hidden, serverUrl, getTokenViewOpenSeaUrl} from "../Config";
import Navbar from "./Navbar";

import React, {useState, useEffect} from 'react'
import {merkleRoot, merkleLeafHex, merkleCheckAddress, merkleProof, containsAddressInList, getAddressList} from '../merkle/use-merkle'


function isValidEthereumAddress(address) {
    try {
        return ethers.isAddress(address);
    } catch (error) {
        return false;
    }
}

function checkWhitelisted(address) {
    try {
        return containsAddressInList(address);
    } catch (error) {
        return false;
    }
}

function MerkleTest() {

  const [walletAddress, setWalletAddress] = useState("");
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [isValidEthWallet, setIsValidEthWallet] = useState(false);

  function handleTextInput(event) {
    const newWalletAddress = event.target.value.toLowerCase();
    setWalletAddress(newWalletAddress);
  }

  useEffect(()=>{
    if (walletAddress !== ""){
      setIsValidEthWallet(isValidEthereumAddress(walletAddress));
      setIsWhitelisted(checkWhitelisted(walletAddress));
    } else {
      setIsValidEthWallet(false);
      setIsWhitelisted(false);
    }

  }, [walletAddress]);

  return (
    <Style.Window>
        <Style.WindowTitleBar>
          <Style.WindowTitle>Merkle Tool</Style.WindowTitle>
        </Style.WindowTitleBar>

        <Style.WindowContent>
            <strong><Style.IconLogo/> Merkle</strong>
            <Style.Break/>
            <p>Check whitelist elligibiliy by typing in your full ETH wallet address below.</p>

            <Style.Break/>
                <p><strong>ETH Wallet Address:</strong></p>
               <Style.TextInput type="text" value={walletAddress} onChange={handleTextInput} placeholder="(example: 0xfffff...)" />
            <Style.Break/>

            <Style.Break/>
            <Style.Divider/>
            <Style.Break/>
            <p>🌳 Merkle Root:</p>
            <p><strong>{merkleRoot()}</strong></p>
            <Style.Break/>
            

             {isWhitelisted && <Style.TransactionSuccessMsg>'{walletAddress}' is whitelisted! ✔️</Style.TransactionSuccessMsg>}
            {isValidEthWallet  && !isWhitelisted && walletAddress !== "" &&  <Style.TransactionErrorMsg>'{walletAddress}' is not whitelisted! ❌ </Style.TransactionErrorMsg>}
            {!isValidEthWallet && walletAddress !== "" &&  <Style.TransactionErrorMsg>'{walletAddress}' is not a valid wallet address! ❌ </Style.TransactionErrorMsg>}


            <Style.Break/>
            {isValidEthWallet && 
              <>
                <p>🍃 Merkle Leaf:</p>
                <Style.TransactionSuccessMsg>{merkleLeafHex(walletAddress)}</Style.TransactionSuccessMsg>
                <Style.Break/>
                <p>🗒️ Merkle Proof:</p>
                <Style.TransactionSuccessMsg>[{merkleProof(walletAddress).join(',')}]</Style.TransactionSuccessMsg>
              </>
            }
        </Style.WindowContent>
    </Style.Window>
  )
}

function MerkleAddresses() {

  const addresses = getAddressList();

  return (
     <Style.Window>
        <Style.WindowTitleBar>
          <Style.WindowTitle>Merkle Addresses (Whitelists)</Style.WindowTitle>
        </Style.WindowTitleBar>

        <Style.WindowContent>
            <p><strong>Total Whitelisted Addresses: {addresses.length}</strong></p>
            {addresses.map((address, index) => (
              <div key={index}>{index}: {address}</div>
            ))}
        </Style.WindowContent>
    </Style.Window>
  )
}

function Merkle() {
    return (
      <>
       <Style.ColumnWide>
          <MerkleTest/>
          <MerkleAddresses/>
        </Style.ColumnWide>
        </>
    )
}

function Server() {

  const [serverResponse, setServerResponse] = useState(null);
  const [error, setError] = useState(null);

  useEffect(()=>{
    fetch(serverUrl)
      .then(response => response.json())
      .then(data => {
        setServerResponse(data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(error.toString());
      });

  },[]);

  return (
     <Style.Window>
      <Style.WindowTitleBar>
        <Style.WindowTitle>Overview</Style.WindowTitle>
      </Style.WindowTitleBar>

      <Style.WindowContent>
          <p><strong>Contract</strong>: <a href={contract.etherscan} target="_blank">{contract.address}</a></p>
          <p><strong>Network</strong>: {network.chain.name} ({network.chain.id})</p>

          <Style.Break/>
          <Style.Divider/>
          <Style.Break/>

          <p><strong>Server Response</strong> (<a href={serverUrl} target="_blank">{serverUrl}</a>):</p>
          {serverResponse && <div><pre>{JSON.stringify(serverResponse, null, 2)}</pre></div>}
          {error && <Style.TransactionErrorMsg>{error}</Style.TransactionErrorMsg>}
      </Style.WindowContent>
    </Style.Window>
  )
}

const GardenIFrame = styled.iframe`
  width: 100%;
  border: 0px;
`;

function DemoToken({selectedTokenId}) {

  const [selectedTokenData, setSelectedTokenData] = useState(null);
  const [error, setError] = useState(null);

  async function refresh() {
    setSelectedTokenData(null);
    setError(null);

    fetch(`${serverUrl}/garden/${selectedTokenId}`)
      .then(response => response.json())
      .then(data => {
        setSelectedTokenData(data);
      })
      .catch(error => {
        console.error(`Error fetching token #${selectedTokenId} data:`, error);
        setError(error.toString());
    });  
  }

  async function forceUpdateToken() {
    setSelectedTokenData(null);
    setError(null);

    fetch(`${serverUrl}/garden/refresh/${selectedTokenId}`)
      .then(response => response.json())
      .then(data => {
        refresh();
      })
      .catch(error => {
        console.error(`Error forcing update on token token #${selectedTokenId} data:`, error);
        setError(error.toString());
    });  
  }

  // fetch token data
  useEffect(()=>{
    if (selectedTokenId == null)
      return;

    refresh();

  },[selectedTokenId]);

  return (
    <>
      {error && <Style.TransactionErrorMsg>{error}</Style.TransactionErrorMsg>}

      {selectedTokenId && <>

        {selectedTokenData && <>
          <p><strong>Community Garden #{selectedTokenId}</strong></p>
            <Style.Button onClick={()=>refresh()}>🔁 Refresh</Style.Button>
            <Style.Button onClick={()=>forceUpdateToken()}>📥 Force Update</Style.Button>
            <Style.Break/>
            <p>- URL: <a href={`/token/${selectedTokenId}`} target="_blank">{`/token/${selectedTokenId}`}</a></p>
            <p>- OpenSea: <a href={getTokenViewOpenSeaUrl(selectedTokenId)} target="_blank">View Community Garden #{selectedTokenId}</a></p>


          <div>
            <p>- Renderer: <a href={network.getAccountUrl(selectedTokenData.addressRenderer)} target="_blank">Renderer Contract</a></p>
            <Style.Break/>
            <p><strong>JSON</strong>: <a href={`${serverUrl}/garden/${selectedTokenId}`} target="_blank">{`${serverUrl}/garden/${selectedTokenId}`}</a></p>
            <pre>{JSON.stringify((({ html, ...rest }) => rest)(selectedTokenData), null, 2)}</pre>
          </div>
          <Style.Break/>
          <GardenIFrame srcDoc={(selectedTokenData.html)} title="Embedded HTML" width="100%" height="300"/>
        </>}
      </>}
    </>
  )
}

function Tokens() {

  const [serverResponse, setServerResponse] = useState(null);
  const [error, setError] = useState(null);

  const [selectedTokenId, setSelectedTokenId] = useState(null);
  const [selectedTokenData, setSelectedTokenData] = useState(null);

  useEffect(()=>{
    fetch(`${serverUrl}/gardens`)
      .then(response => response.json())
      .then(data => {
        setServerResponse(data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(error.toString());
      });

  },[]);

  return (
     <Style.Window>
      <Style.WindowTitleBar>
        <Style.WindowTitle>Tokens</Style.WindowTitle>
      </Style.WindowTitleBar>

      <Style.WindowContent>

          <p><strong>Contract</strong>: <a href={contract.etherscan} target="_blank">{contract.address}</a></p>
          <p><strong>Network</strong>: {network.chain.name} ({network.chain.id})</p>
          <p><strong>MongoDB</strong>: <a target="_blank" href={`https://railway.app/project/eea56caf-d90b-4678-adf6-78c587a994de/plugin/2723f22a-1242-431e-b3f0-81af6491e718`}>View</a></p>

          <Style.Break/>
          <Style.Divider/>
          <Style.Break/>

          {serverResponse && serverResponse.enabled &&  <>
              <p><strong>Enabled</strong> Community Gardens:</p>
              <Style.Break/>
              <p>
                {serverResponse.enabled.map((token, index) => ( 
                    <React.Fragment key={token.tokenId}>
                      <Style.Button onClick={()=>setSelectedTokenId(token.tokenId)}>{token.tokenId} {token.icon}</Style.Button>
                      {" "}
                    </React.Fragment>
                ))}
              </p>
              <Style.Break/>
              <Style.Divider/>
              <Style.Break/>

              <p>Disabled #:</p>
              <Style.Break/>

              {serverResponse.disabled.map((token, index) => ( 
                    <React.Fragment key={token.tokenId}>
                      <Style.Button onClick={()=>setSelectedTokenId(token.tokenId)}>{token.tokenId} {token.icon}</Style.Button>
                      {" "}
                    </React.Fragment>
                ))}

              {serverResponse.disabled.length == 0  && <p>(None)</p>}
          </>}

          <Style.Break/>
          <Style.Divider/>
          <Style.Break/>

          {/*<p><strong>Server Response</strong> (<a href={serverUrl} target="_blank">{serverUrl}</a>):</p>
          {serverResponse && <div><pre>{JSON.stringify(serverResponse, null, 2)}</pre></div>}*/}
          {error && <Style.TransactionErrorMsg>{error}</Style.TransactionErrorMsg>}

          {selectedTokenId && <>
            
            <DemoToken selectedTokenData={selectedTokenData} selectedTokenId={selectedTokenId}/>

          </>}
      </Style.WindowContent>
    </Style.Window>
  )
}


function MessageUtil() {

  //const [walletAddress, setWalletAddress] = useState("0xa9eEf692f818501E7B0A7538F35aF0455b9e6F96");
  const [userMessage, setUserMessage] = useState("");
  const [jsonResults, setJsonResults] = useState(null);
  const [error, setError] = useState(null);

  async function checkMessage() {
    console.log(`Checking message: "${userMessage}"`);

    fetch(`${serverUrl}/message/sign`, {
        method: 'POST',
        mode: 'cors', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: userMessage,
          //address: walletAddress,
        })
      })
      .then(response => response.json())
      .then(data => {
        console.log("responseData", data);
        setJsonResults(data);

      })
      .catch(error => {
        console.error('Error with POST request:', error);
        setError(error.toString());
      });
    }

    return (
      <>
       <Style.ColumnWide>
          <Server/>
          <Style.Window>
              <Style.WindowTitleBar>
                <Style.WindowTitle>Message Signer</Style.WindowTitle>
              </Style.WindowTitleBar>

              <Style.WindowContent>
               
                <p><strong>Message:</strong></p>
               <Style.TextInput type="text" value={userMessage} onChange={(event)=>{setUserMessage(event.target.value)}} placeholder="Your message (to sign)..." />

                {/*<p><strong>Wallet Address:</strong></p>
               <Style.TextInput type="text" value={walletAddress} onChange={(event)=>{setWalletAddress(event.target.value)}} placeholder="Your message" />*/}
                <Style.Button  onClick={() => checkMessage()} disabled={userMessage.length == 0 }>Check</Style.Button>
               <Style.Break/>
               <Style.Divider/>
               <Style.Break/>

                 <p><strong>Results</strong></p>
                {jsonResults != null && <Style.ShowJson>{JSON.stringify(jsonResults, null, 2)}</Style.ShowJson>}
                {error && <Style.TransactionErrorMsg>{error}</Style.TransactionErrorMsg>}

              </Style.WindowContent>
          </Style.Window>

        </Style.ColumnWide>
        </>
    )
}


function AdminPage() {
  return (
    <Style.Background>
      <Navbar/>
      <Style.Container>

       
      <MessageUtil/>
      <Merkle/>
      <Tokens/>

      </Style.Container>
    </Style.Background>
  );
}

export default AdminPage;
