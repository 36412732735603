import { WagmiConfig, createConfig, configureChains  } from 'wagmi'
import { mainnet, base, baseGoerli } from 'wagmi/chains'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import {useEffect} from 'react'
import { Buffer } from "buffer";

import React from 'react';

import Navbar from "./components/Navbar";
import GardenView from "./components/GardenView";

import {
  createBrowserRouter,
  RouterProvider,
  useParams 
} from "react-router-dom";

//import { infuraProvider } from 'wagmi/providers/infura'
//import { publicProvider } from 'wagmi/providers/public'
//import { InjectedConnector } from 'wagmi/connectors/injected'

import GlobalStyle from './components/GlobalStyle'
import Index from './components/Index'
import AboutPage from './components/AboutPage'
import AdminPage from './components/AdminPage'
import ViewSinglePage from './components/ViewSinglePage'
import WhitelistCheckerPage from './components/WhitelistCheckerPage'
import ErrorPage from './components/ErrorPage'

window.Buffer = window.Buffer || Buffer;
 
 const { publicClient, webSocketPublicClient  } = configureChains(
  [mainnet, base, baseGoerli],
  [
    alchemyProvider({ apiKey: 'k5u1H1zQC3iHJZJGTUzZeUpw11HbzT0L' }),
    //publicProvider(),
  ],
)

const config = createConfig({
  autoConnect: true,
  publicClient,
  webSocketPublicClient
})

function GardenViewRoute() {
  let { tokenId } = useParams();
  return <GardenView tokenId={Number(tokenId)} />;
}

function GardenViewSingleRoute() {
  let { tokenId } = useParams();
  return <ViewSinglePage tokenId={Number(tokenId)} />;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index/>,
  },

  {
    path: "/about",
    element: <AboutPage/>,
  },

   {
    path: "/admin",
    element: <AdminPage/>,
  },

  {
    path: "/whitelist",
    element: <WhitelistCheckerPage/>,
  },

  {
    path: "/token/:tokenId",
    element: <GardenViewRoute/>,
  },

  {
    path: "/view/:tokenId",
    element: <GardenViewSingleRoute/>,
  },

  {
    path: "*",
    element: <ErrorPage />,
  },
]);

function App() {

  return (
    <WagmiConfig config={config}>
        <GlobalStyle />
        <RouterProvider router={router} />
    </WagmiConfig>
  )
}

export default App;