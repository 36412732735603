import * as Style from "./StyledComponents";
import GardenView from "./GardenView";

function Index() {
  return (
      <GardenView tokenId={3}/>
  );
}

export default Index;
